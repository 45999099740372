import { LeadEventKind } from 'core/lib/graphql/types.d';
import {
  LeadEventCreateMutationVariables,
  useLeadEventCreateMutation,
} from 'core/lib/graphql/_gen/lead-event-create';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useEffect } from 'react';
import { externalEvents } from '../events/external-event';

type UTMVariables = 'utmSource' | 'utmMedium' | 'utmCampaign' | 'utmContent';

const leadEventUTMParamsDict: Record<
  string,
  keyof Pick<LeadEventCreateMutationVariables, UTMVariables>
> = {
  utm_source: 'utmSource',
  utm_medium: 'utmMedium',
  utm_campaign: 'utmCampaign',
  utm_content: 'utmContent',
};

export const getUTMParamsFromQuery = (query: ParsedUrlQuery): Record<UTMVariables, string> =>
  Object.entries(leadEventUTMParamsDict).reduce((acc, [key, value]) => {
    const utm = query[key];
    if (utm != null && typeof utm === 'string' && utm.length > 0) {
      acc[value] = utm;
    }
    return acc;
  }, {} as Record<UTMVariables, string>);

const leadEventToAnalyticsStepDict: Partial<Record<LeadEventKind, [number, string]>> = {
  [LeadEventKind.CheckoutInit]: [1, 'checkout-init'],
  [LeadEventKind.CheckoutPersonalInfo]: [2, 'checkout-personal-info'],
  [LeadEventKind.CheckoutDocuments]: [3, 'checkout-personal-documents'],
  [LeadEventKind.CheckoutAddress]: [4, 'checkout-address'],
  [LeadEventKind.CheckoutAddPaymentMethod]: [5, 'checkout-payment-methods'],
  [LeadEventKind.CheckoutReview]: [6, 'checkout-review'],
  [LeadEventKind.CheckoutFinish]: [7, 'checkout-finish'],
};

export const useUtmLeadEventCreate = ({
  kind = LeadEventKind.MainPageView,
  finality,
  ...rest
}: Partial<LeadEventCreateMutationVariables>) => {
  const { query, isReady } = useRouter();
  const [leadCreate, leadCreateQ] = useLeadEventCreateMutation();
  useEffect(() => {
    if (!leadCreateQ.called && isReady) {
      const UTMParams = getUTMParamsFromQuery(query);
      const [step, stepName] = leadEventToAnalyticsStepDict[kind] ?? [];
      if (step != null && stepName != null) {
        externalEvents.checkoutStep(step, stepName);
      }
      if (process.env.NODE_ENV !== 'development') {
        leadCreate({ variables: { kind, finality, ...UTMParams, ...rest } });
      }
    }
  }, [query, finality, kind, rest, leadCreate, leadCreateQ, isReady]);
};
