import * as Types from '../types';

import { gql } from '@apollo/client';
import { MutationErrorFieldsFragmentDoc } from '../fragments/_gen/mutation-error.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LeadEventFieldsFragment = {
  errors?: Array<{ field?: string | null; message?: string | null }> | null;
  leadEvent?: { id: string } | null;
};

export type LeadEventCreateMutationVariables = Types.Exact<{
  kind: Types.LeadEventKind;
  finality?: Types.InputMaybe<Types.FinalityKind>;
  promocode?: Types.InputMaybe<Types.Scalars['String']>;
  items?: Types.InputMaybe<Array<Types.ProductItemInputType> | Types.ProductItemInputType>;
  utmSource?: Types.InputMaybe<Types.Scalars['String']>;
  utmMedium?: Types.InputMaybe<Types.Scalars['String']>;
  utmCampaign?: Types.InputMaybe<Types.Scalars['String']>;
  utmContent?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type LeadEventCreateMutation = {
  leadEventCreate?: {
    errors?: Array<{ field?: string | null; message?: string | null }> | null;
    leadEvent?: { id: string } | null;
  } | null;
};

export type LoginLeadEventCreateMutationVariables = Types.Exact<{
  finality?: Types.InputMaybe<Types.FinalityKind>;
}>;

export type LoginLeadEventCreateMutation = {
  leadEventCreate?: {
    errors?: Array<{ field?: string | null; message?: string | null }> | null;
    leadEvent?: { id: string } | null;
  } | null;
};

export const LeadEventFieldsFragmentDoc = gql`
  fragment leadEventFields on LeadEventCreateMutationPayload {
    errors {
      ...mutationErrorFields
    }
    leadEvent {
      id
    }
  }
  ${MutationErrorFieldsFragmentDoc}
`;
export const LeadEventCreateDocument = gql`
  mutation LeadEventCreate(
    $kind: LeadEventKind!
    $finality: FinalityKind
    $promocode: String
    $items: [ProductItemInputType!]
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $utmContent: String
  ) {
    leadEventCreate(
      input: {
        items: $items
        kind: $kind
        finality: $finality
        promocode: $promocode
        utmSource: $utmSource
        utmMedium: $utmMedium
        utmCampaign: $utmCampaign
        utmContent: $utmContent
      }
    ) {
      ...leadEventFields
    }
  }
  ${LeadEventFieldsFragmentDoc}
`;
export type LeadEventCreateMutationFn = Apollo.MutationFunction<
  LeadEventCreateMutation,
  LeadEventCreateMutationVariables
>;

/**
 * __useLeadEventCreateMutation__
 *
 * To run a mutation, you first call `useLeadEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeadEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leadEventCreateMutation, { data, loading, error }] = useLeadEventCreateMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *      finality: // value for 'finality'
 *      promocode: // value for 'promocode'
 *      items: // value for 'items'
 *      utmSource: // value for 'utmSource'
 *      utmMedium: // value for 'utmMedium'
 *      utmCampaign: // value for 'utmCampaign'
 *      utmContent: // value for 'utmContent'
 *   },
 * });
 */
export function useLeadEventCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeadEventCreateMutation,
    LeadEventCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeadEventCreateMutation, LeadEventCreateMutationVariables>(
    LeadEventCreateDocument,
    options,
  );
}
export type LeadEventCreateMutationHookResult = ReturnType<typeof useLeadEventCreateMutation>;
export type LeadEventCreateMutationResult = Apollo.MutationResult<LeadEventCreateMutation>;
export type LeadEventCreateMutationOptions = Apollo.BaseMutationOptions<
  LeadEventCreateMutation,
  LeadEventCreateMutationVariables
>;
export const LoginLeadEventCreateDocument = gql`
  mutation LoginLeadEventCreate($finality: FinalityKind) {
    leadEventCreate(input: { kind: LOGIN, finality: $finality }) {
      ...leadEventFields
    }
  }
  ${LeadEventFieldsFragmentDoc}
`;
export type LoginLeadEventCreateMutationFn = Apollo.MutationFunction<
  LoginLeadEventCreateMutation,
  LoginLeadEventCreateMutationVariables
>;

/**
 * __useLoginLeadEventCreateMutation__
 *
 * To run a mutation, you first call `useLoginLeadEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginLeadEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginLeadEventCreateMutation, { data, loading, error }] = useLoginLeadEventCreateMutation({
 *   variables: {
 *      finality: // value for 'finality'
 *   },
 * });
 */
export function useLoginLeadEventCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginLeadEventCreateMutation,
    LoginLeadEventCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginLeadEventCreateMutation, LoginLeadEventCreateMutationVariables>(
    LoginLeadEventCreateDocument,
    options,
  );
}
export type LoginLeadEventCreateMutationHookResult = ReturnType<
  typeof useLoginLeadEventCreateMutation
>;
export type LoginLeadEventCreateMutationResult =
  Apollo.MutationResult<LoginLeadEventCreateMutation>;
export type LoginLeadEventCreateMutationOptions = Apollo.BaseMutationOptions<
  LoginLeadEventCreateMutation,
  LoginLeadEventCreateMutationVariables
>;
