import { VEROO_ORIGIN } from '@web/common/constants/url';
import { NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';

export const SEO: FC<NextSeoProps> = props => {
  const { asPath } = useRouter();

  const canonicalURL = useMemo(() => {
    const [path] = asPath.split('?');
    return `${VEROO_ORIGIN}${path}`;
  }, [asPath]);

  return <NextSeo titleTemplate="%s - Veroo" canonical={canonicalURL} {...props} />;
};
