import { FinalityKind } from 'core/lib/graphql/types.d';
import { SessionStorageObjectItem } from '../storage/session-storage-object-item';

export const PROMOCODE_STORAGE_KEY = 'verooPromocode';

export class PromocodeStorage extends SessionStorageObjectItem<Record<FinalityKind, string>> {
  static instance: PromocodeStorage;

  static getInstance() {
    if (PromocodeStorage.instance == null) {
      PromocodeStorage.instance = new PromocodeStorage(PROMOCODE_STORAGE_KEY);
    }
    return PromocodeStorage.instance;
  }
}

export const promocodeStorage = PromocodeStorage.getInstance();
